import React, { useEffect } from 'react';
import { Link, useNavigate  } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import { useTranslation } from "react-i18next";

function Navbar() {
	const { t } = useTranslation();

		return (
            <React.Fragment>
                <div id="navigation" className="fixed-top navbar-light bg-faded site-navigation">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-4">
                                <div className="site-logo">
                                    <Link to="/" className="nav-link px-2 text-truncate gen-fontwhite"><img src="assets/images/all-img/logo-ep.png" alt="" /></Link>
                                </div>
                            </div>
                            {/* <!--- END Col --> */}
                            
                            <div className="col-lg-6 col-md-9 col-sm-8 ">
                                <div className="header_right ">
                                    <nav id="main-menu" className="ms-auto">
                                        <ul>
                                            <li><Link className="nav-link" to="/">{ t('navbar.home') }</Link></li>
                                            <li><Link className="nav-link" to="/about-us">{ t('navbar.about-us') }</Link></li>
                                            <li><Link className="nav-link" to="/courses">{ t('navbar.courses') }</Link></li>
                                            <li><Link className="nav-link" to="/Contact">{ t('navbar.contact') }</Link></li>
                                            {/* <li><Link className="nav-link" to="#">Pages <span className="ti-angle-down"></span></Link>
                                                <ul>
                                                    <li><Link className="nav-link" to="about.html">About Us</Link></li>										
                                                    <li><Link className="nav-link" to="team.html">Instructor</Link></li>
                                                    <li><Link className="nav-link" to="team-details.html">Instructor Details</Link></li>
                                                    <li><Link className="nav-link" to="faq.html">FAQ's</Link></li>
                                                    <li><Link className="nav-link" to="event.html">Event</Link></li>
                                                    <li><Link className="nav-link" to="event_single.html">Event Single</Link></li>
                                                    <li><Link className="nav-link" to="error.html">404 Page</Link></li>										
                                                    <li><Link className="nav-link" to="login.html">Login</Link></li>
                                                    <li><Link className="nav-link" to="register.html">Register</Link></li>
                                                </ul>
                                            </li> */}
                                            {/* <li><Link className="nav-link" to="course.html">Courses <span className="ti-angle-down"></span></Link>
                                                <ul>
                                                    <li><Link className="nav-link" to="course.html">Courses 01</Link></li>
                                                    <li><Link className="nav-link" to="course2.html">Courses 02</Link></li>
                                                    <li><Link className="nav-link" to="course3.html">Courses 03</Link></li>
                                                    <li><Link className="nav-link" to="course-sidebar.html">Course Sidebar</Link></li>
                                                    <li><Link className="nav-link" to="single_course.html">Course details</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link className="nav-link" to="shop.html">Shop <span className="ti-angle-down"></span></Link>
                                                <ul>
                                                    <li><Link className="nav-link" to="shop.html">Shop</Link></li>
                                                    <li><Link className="nav-link" to="single_shop.html">Single Shop</Link></li>
                                                    <li><Link className="nav-link" to="cart.html">Cart Page</Link></li>
                                                    <li><Link className="nav-link" to="checkout.html">Checkout page</Link></li>
                                                </ul>
                                            </li>
                                            <li><Link className="nav-link" to="blog.html">Blog <span className="ti-angle-down"></span></Link>
                                                <ul>
                                                    <li><Link className="nav-link" to="blog.html">Blog List</Link></li>
                                                    <li><Link className="nav-link" to="blog_single.html">Single Blog</Link></li>
                                                </ul>
                                            </li> */}
                                        </ul>
                                    </nav>
                                    <div id="mobile_menu"></div>
                                </div>
                            </div>
                            {/* <!--- END Col --> */}
                            <div className="col-lg-4 col-md-3 col-sm-8">
                                {/* <div className="home_lc">
                                    <Link to="#" className="hlc">
                                    <i className="ti-heart"></i>
                                    <span className="gactive">0</span>
                                </Link>
                                    <Link to="#" className="hlc">
                                    <i className="ti-shopping-cart-full"></i>
                                    <span className="gactive">2</span>
                                </Link>
                                </div>
                                <div className="call_to_action">
                                    <Link className="btn_one" to="login.html">{ t('navbar.login') }</Link>
                                    <Link className="btn_two" to="register.html">{ t('navbar.sign-up') }</Link> */}
                                {/* </div> */}
                                {/* <!--- END SOCIAL PROFILE -->					 */}
                            </div>
                            {/* <!--- END Col --> */}
                        </div>
                        {/* <!--- END ROW --> */}
                    </div>
                    {/* <!--- END CONTAINER --> */}
                </div> 
            </React.Fragment>
		);
}

export default Navbar;