import React, { useEffect } from 'react';

function Loader() {

		return (
            <React.Fragment>
                {/* <!-- START PRELOADER --> */}
                <div id="loader-wrapper">
                        <div id="loader"></div>
                        <div className="loader-section section-left"></div>
                        <div className="loader-section section-right"></div>
                    </div>
                {/* <!-- END PRELOADER --> */}
            </React.Fragment>
		);
}

export default Loader;