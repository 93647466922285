import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

function TerminosCondiciones() {

	const { t } = useTranslation();

		return (
            <React.Fragment>
                
                {/* <!-- START SECTION TOP --> */}
                <section class="section-top">
                    <div class="container">
                        <div class="col-lg-10 offset-lg-1 text-center">
                            <div class="section-top-title wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h1>{t('TerminosCondiciones.title')}</h1>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li> / {t('TerminosCondiciones.title')}</li>
                                </ul>
                            </div>
                            {/* <!-- //.HERO-TEXT --> */}
                        </div>
                        {/* <!--- END COL --> */}
                    </div>
                    {/* <!--- END CONTAINER --> */}
                </section>	
                
                {/* <!-- END SECTION TOP --> */}
                
                
                
                {/* <!-- START ABOUT US HOME ONE --> */}
                <section class="ab_one section-padding">
                    <div class="container">									
                        <div class="row">								
                            {/* <div class="col-lg-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div class="ab_img">
                                    <img src="assets/images/all-img/about2.png" class="img-fluid" alt="image" />
                                </div>
                            </div> */}
                            {/* <!--- END COL -->					 */}
                            <div class="col-lg-12 col-sm-12 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div class="ab_content">
                                    <h2>{t('TerminosCondiciones.title-1')}<u><span>{t('TerminosCondiciones.title')}</span></u></h2>
                                    <h5>{t('TerminosCondiciones.document.title-1')}</h5>
                                    <p>{t('TerminosCondiciones.document.p-1-1')}</p>
                                    <p>{t('TerminosCondiciones.document.p-1-2')}</p>
                                    <h5>{t('TerminosCondiciones.document.title-2')}</h5>
                                    <p>{t('TerminosCondiciones.document.p-2-1')}</p>
                                    <p>{t('TerminosCondiciones.document.p-2-2')}</p>
                                    <p>{t('TerminosCondiciones.document.p-2-3')}</p>
                                    <h5>{t('TerminosCondiciones.document.title-3')}</h5>
                                    <p>{t('TerminosCondiciones.document.p-3-1')}</p>
                                    <p>{t('TerminosCondiciones.document.p-3-2')}</p>
                                    <p>{t('TerminosCondiciones.document.p-3-3')}</p>
                                    <p>{t('TerminosCondiciones.document.p-3-4')}</p>
                                    <p>{t('TerminosCondiciones.document.p-3-5')}</p>
                                    <p>{t('TerminosCondiciones.document.p-3-6')}</p>
                                    <p>{t('TerminosCondiciones.document.p-3-7')}</p>
                                    {/* <a class="btn_one" href="about.html">Read More us</a> */}
                                </div>						
                            </div>
                            {/* <!--- END COL -->								   */}
                        </div>
                        {/* <!--- END ROW --> */}
                    </div>
                    {/* <!--- END CONTAINER --> */}
                </section>
                
                {/* <!-- END ABOUT US HOME ONE -->	 */}
            </React.Fragment>
		);
}

export default TerminosCondiciones;