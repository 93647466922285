import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"; /* Agregamos la libreria para las rutas */

function Course() {

	const { t } = useTranslation();

		return (
            <React.Fragment>
                
                {/* <!-- START SECTION TOP --> */}
                <section class="section-top">
                    <div class="container">
                        <div class="col-lg-10 offset-lg-1 text-center">
                            <div class="section-top-title wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h1>{t('courses.title')}</h1>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li> / {t('courses.title')}</li>
                                </ul>
                            </div>
                            {/* <!-- //.HERO-TEXT --> */}
                        </div>
                        {/* <!--- END COL --> */}
                    </div>
                    {/* <!--- END CONTAINER --> */}
                </section>	
                
                {/* <!-- END SECTION TOP --> */}

                
                {/* START COURSE --> */}
                <div className="best-cpurse section-padding">
                    <div className="container">
                        <div className="section-title">
                        {/* <h2>Popular Courses</h2> */}
                            <p>{t('home.courses.title-1')}<span><u>{t('home.courses.title-2')}</u></span></p>
                        </div>				
                        <div class="cp_content">
                            <p>{t('home.courses.description')}</p>
                        </div>			
                        <div className="row">					
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c1.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$49</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-1')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Arts & Design</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-1')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 45m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>30 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c2.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$39</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-2')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Social</Link> */}
                                        {/* <h3><a href="single_course.html">{t('home.courses.item-2')}</Link></h3>
                                        <span><i className="fa fa-calendar"></i>5 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>4h 15m</span>
                                        <span><i className="fa fa-star"></i>4.7</span>
                                        <span><i className="fa fa-table"></i><strong>21 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c3.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$29</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-3')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Design</Link> */}
                                        {/* <h3><a href="#">{t('home.courses.item-3')}</Link></h3>
                                        <span><i className="fa fa-calendar"></i>4 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>6h 25m</span>
                                        <span><i className="fa fa-star"></i>4.8</span>
                                        <span><i className="fa fa-table"></i><strong>33 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c4.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$59</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-4')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Technology</Link> */}
                                        {/* <h3><a href="#">{t('home.courses.item-4')}</Link></h3>
                                        <span><i className="fa fa-calendar"></i>7 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>7h 45m</span>
                                        <span><i className="fa fa-star"></i>4.7</span>
                                        <span><i className="fa fa-table"></i><strong>11 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c5.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$69</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-5')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Data Science</Link> */}
                                        {/* <h3><a href="#">{t('home.courses.item-5')}</Link></h3>
                                        <span><i className="fa fa-calendar"></i>5 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>4h 55m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>41 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c6.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$99</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-6')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Development</Link> */}
                                        {/* <h3><a href="#">{t('home.courses.item-6')}</Link></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 10m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>37 Seats Available</strong></span> */}
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL --> */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c7.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$99</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-7')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Development</Link> */}
                                        {/* <h3><a href="#">{t('home.courses.item-7')}</Link></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 10m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>37 Seats Available</strong></span> */}
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL --> */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c8.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$99</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-8')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Development</Link> */}
                                        {/* <h3><a href="#">{t('home.courses.item-8')}</Link></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 10m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>37 Seats Available</strong></span> */}
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL --> */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c9.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$99</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-9')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Development</Link> */}
                                        {/* <h3><a href="#">{t('home.courses.item-9')}</Link></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 10m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>37 Seats Available</strong></span> */}
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL --> */}
                            {/* <div className="col-lg-12 text-center">
                                <div className="cc_btn">
                                    <a className="btn_one" href="course.html">View All Course</Link>
                                </div>
                            </div> */}
                            {/* END COL -->				 */}
                        </div>
                        {/* END ROW -->		 */}
                    </div>
                    {/* END CONTAINER --> */}
                </div>
                
                {/* END COURSE --> */}
            </React.Fragment>
		);
}

export default Course;