import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"; /* Agregamos la libreria para las rutas */

function Home() {

	const { t } = useTranslation();

		return (
            <React.Fragment>
            {/* 
            //  START HOME --> */}
                <section  id="home" className="home_bg" style={{backgroundImage: 'url(assets/images/banner/home.png)', backgroundsize:'cover', backgroundPosition: 'center center'}}>	
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 col-xs-12">	
                                <div className="home_content">
                                    <h1>{ t('home.start.title-1') }<span>{ t('home.start.title-2') }</span>{ t('home.start.title-3') }<span>{ t('home.start.title-4') }</span>{ t('home.start.title-5') }<span>{ t('home.start.title-6') }</span></h1>
                                    {/* <p>It is a long established fact that reader will be distracted readable content of a page when.</p>						 */}
                                </div>
                                <div className="home_btn">
                                <Link className="cta" to="/Contact"><span>Aprendizaje para todos</span>
                                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                                        <path d="M1,5 L11,5"></path>
                                        <polyline points="8 1 12 5 8 9"></polyline>
                                    </svg>
                                    </Link>
                                </div>		
                            </div>
                            {/* 
                            //  END COL--> */}
                            <div className="col-lg-6 col-sm-6 col-xs-12">	
                                <div className="home_me_img">
                                    <img src="assets/images/all-img/home-image-1.png" className="img-fluid" alt="" />
                                    <div className="home_ps">
                                        <img src="assets/images/icon/user2.svg" alt="" />
                                        <h2>{t('home.start.home-ps.h2')}</h2>
                                        <span>{t('home.start.home-ps.span')}</span>
                                    </div>
                                    <div className="home_ps2">
                                        <img src="assets/images/icon/file2.svg" alt="" />
                                        <h2>{t('home.start.home-ps2.h2')}</h2>
                                        <span>{t('home.start.home-ps2.h2')}</span>
                                    </div>
                                </div>		
                            </div>
                            {/* npm 
                            //  END COL--> */}
                        </div>
                        {/* 
                        // - END ROW -->			 */}
                    </div>
                    {/* 
                    // - END CONTAINER --> */}
                </section>
                {/* 
                //  END  HOME --> */}
                
                 {/* START COURSE PROMOTION --> */}
                <section className="course_promo section-padding">
                    <div className="container">									
                        <div className="row">								
                            <div className="col-lg-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="cp_content">
                                    {/* <h4>Best Online Learning Platform</h4> */}
                                    <h2>{t('home.promotion.title-1')}<span><u>{t('home.promotion.title-2')}</u></span>{t('home.promotion.title-3')}<span><u>{t('home.promotion.title-4')}</u></span></h2>
                                    <p>{t('home.promotion.description')}</p>
                                    <ul>
                                        <li><span className="ti-check"></span>{t('home.promotion.item-1')}</li>
                                        <li><span className="ti-check"></span>{t('home.promotion.item-2')}</li>
                                        <li><span className="ti-check"></span>{t('home.promotion.item-3')}</li>
                                        <li><span className="ti-check"></span>{t('home.promotion.item-4')}</li>
                                    </ul>
                                </div>
                                <div className="cp_btn">
                                <Link className="cta" to="/Contact"><span>{t('home.promotion.buttom')}</span>
                                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                                        <path d="M1,5 L11,5"></path>
                                        <polyline points="8 1 12 5 8 9"></polyline>
                                    </svg>
                                    </Link>
                                </div>
                            </div>
                            {/* - END COL -->						 */}
                            <div className="col-lg-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                <div className="cp_img">
                                    <img src="assets/images/all-img/promo.png" className="img-fluid" alt="image" />
                                    
                                     {/* <div className="wc_year">
                                        <h3>20 Years of Experience <br />from 2002</h3>
                                    </div> --> */}
                                </div>
                            </div>
                            {/* - END COL -->			   */}
                        </div>
                        {/* - END ROW --> */}
                    </div>
                    {/* - END CONTAINER --> */}
                </section>
                
                 {/* END COURSE PROMOTION -->	 */}


                            
                            {/* <!-- START FAQ --> */}
                <section class="faq_area section-padding">
                    <div class="container">													
                        <div class="section-title">
                            {/* <h2>Frequently Asked Question</h2> */}
                            <p><span><u>{t('home.faq.title')}</u></span></p>
                        </div>			
                        <div class="cp_content">
                            <p>{t('home.faq.description')}</p>
                        </div>			
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-xs-12">
                                <div class="pt_faq">
                                    <img src="assets/images/all-img/faq.png" class="img-fluid" alt="image" />
                                </div>
                            </div>
                            {/* <!-- END COL  -->	 */}
                            <div class="col-lg-6 col-sm-6 col-xs-12">
                                <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        {t('home.faq.item-1.title')}
                                    </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        {t('home.faq.item-1.description')}
                                    </div>
                                    </div>
                                </div>
                                {/* <!-- END ACCORDION ITEM  --> */}
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        {t('home.faq.item-2.title')}
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        {t('home.faq.item-2.description')}
                                    </div>
                                    </div>
                                </div>
                                {/* <!-- END ACCORDION ITEM  --> */}
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        {t('home.faq.item-3.title')}
                                    </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        {t('home.faq.item-3.description')}
                                    </div>
                                    </div>
                                </div>
                                {/* <!-- END ACCORDION ITEM  --> */}
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        {t('home.faq.item-4.title')}
                                    </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        {t('home.faq.item-4.description')}
                                    </div>
                                    </div>
                                </div>
                                {/* <!-- END ACCORDION ITEM  -->	 */}
                                </div>						
                            </div>
                            {/* <!-- END COL  -->	 */}
                        </div>
                        {/* <!--END  ROW  --> */}
                    </div>
                    {/* <!--- END CONTAINER --> */}
                </section>
                
                {/* <!-- END FAQ --> */}

                {/* START COURSE --> */}
                <div className="best-cpurse section-padding">
                    <div className="container">
                        <div className="section-title">
                        {/* <h2>Popular Courses</h2> */}
                            <p>{t('home.courses.title-1')}<span><u>{t('home.courses.title-2')}</u></span></p>
                        </div>				
                        <div class="cp_content">
                            <p>{t('home.courses.description')}</p>
                        </div>			
                        <div className="row">					
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c1.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$49</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-1')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Arts & Design</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-1')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 45m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>30 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c2.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$39</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-2')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Social</a> */}
                                        {/* <h3><a href="single_course.html">{t('home.courses.item-2')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>5 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>4h 15m</span>
                                        <span><i className="fa fa-star"></i>4.7</span>
                                        <span><i className="fa fa-table"></i><strong>21 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c3.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$29</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-3')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Design</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-3')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>4 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>6h 25m</span>
                                        <span><i className="fa fa-star"></i>4.8</span>
                                        <span><i className="fa fa-table"></i><strong>33 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c4.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$59</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-4')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Technology</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-4')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>7 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>7h 45m</span>
                                        <span><i className="fa fa-star"></i>4.7</span>
                                        <span><i className="fa fa-table"></i><strong>11 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c5.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$69</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-5')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Data Science</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-5')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>5 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>4h 55m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>41 Seats Available</strong></span> */}
                                        
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL -->			 */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c6.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$99</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-6')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Development</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-6')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 10m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>37 Seats Available</strong></span> */}
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL --> */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c7.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$99</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-7')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Development</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-7')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 10m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>37 Seats Available</strong></span> */}
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL --> */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c8.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$99</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-8')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Development</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-8')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 10m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>37 Seats Available</strong></span> */}
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL --> */}
                            <div className="col-lg-4 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                <div className="course-slide">
                                    <div className="course-img">
                                        <img src="assets/images/all-img/c9.png" alt="" />
                                        <div className="course-date">
                                            {/* <span className="month">$99</span> */}
                                        </div>
                                    </div>
                                    <div className="course-content"><Link className="c_btn" to="/Contact">{t('home.courses.item-9')}</Link>
                                    {/* <div className="course-content"><a className="c_btn" href="#">Development</a> */}
                                        {/* <h3><a href="#">{t('home.courses.item-9')}</a></h3>
                                        <span><i className="fa fa-calendar"></i>3 Lessons</span>
                                        <span><i className="fa fa-clock-o"></i>3h 10m</span>
                                        <span><i className="fa fa-star"></i>4.9</span>
                                        <span><i className="fa fa-table"></i><strong>37 Seats Available</strong></span> */}
                                    </div>
                                </div>
                                {/* END COURSE SLIDE -->	 */}
                            </div>
                            {/* END COL --> */}
                            {/* <div className="col-lg-12 text-center">
                                <div className="cc_btn">
                                    <a className="btn_one" href="course.html">View All Course</a>
                                </div>
                            </div> */}
                            {/* END COL -->				 */}
                        </div>
                        {/* END ROW -->		 */}
                    </div>
                    {/* END CONTAINER --> */}
                </div>
                
                {/* END COURSE --> */}
                
                 {/* START NEWSLETTER --> */}
                <section className="newsletter_area section-padding">
                    <div className="container">
                        <div className="row text-center">			
                            <div className="col-lg-6 offset-lg-3 col-sm-12 col-xs-12">
                                <div className="subs_form">
                                    <h3>{t('home.newletter.title')}</h3>
                                    <p>{t('home.newletter.description')}</p>
                                    <form action="/Contact" className="home_subs">
                                        {/*<input type="text" className="subscribe__input" placeholder="Enter your Email Address" />
                                        <button type="button" className="subscribe__btn"><i className="fa fa-paper-plane-o"></i></button>*/}
                                    </form>
                                </div>
                            </div>
                             {/* END COL --> */}
                        </div>
                         {/* END ROW -->	 */}
                    </div>
                    {/* - END CONTAINER -->	 */}
                </section>
                
                 {/* END NEWSLETTER --> */}
                
                
            </React.Fragment>
		);
}

export default Home;