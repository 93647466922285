import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {useState} from 'react';
import { useNavigate } from "react-router-dom";

function Contact() {

	const { t } = useTranslation();
    const [inputname, setInputname] = useState('');
        const [inputemail, setInputemail] = useState('');
        const [inputtel, setInputtel] = useState('');
        const [inputmsg, setInputmsg] = useState('');
        const navigate = useNavigate();

    //enviarmensaje();

    function enviarmensaje(){
        
        console.log("safasdf dede react");
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("nombre", inputname);
        urlencoded.append("correo", inputemail);
        urlencoded.append("telefono", inputtel);
        urlencoded.append("mensaje", inputmsg);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch("https://req.espayservice.com/", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

        alert("Su mensaje ha sido enviado");
        navigate("/");
        //setInputname('');
        //setInputemail('');
        //setInputtel('');
        //setInputmsg('');

    }



    const handlechangeinputname = event => {
        setInputname(event.target.value);
        console.log("value is: ",event.target.value);
    }

    const handlechangeinputemail = event => {
        setInputemail(event.target.value);
        console.log("value is: ",event.target.value);
    }
    const handlechangeinputtel = event => {
        setInputtel(event.target.value);
        console.log("value is: ",event.target.value);
    }
    const handlechangeinputmsg = event => {
        setInputmsg(event.target.value);
        console.log("value is: ",event.target.value);
    }

    

		return (
            <React.Fragment>
                
                {/* <!-- START SECTION TOP --> */}
                <section className="section-top">
                    <div className="container">
                        <div className="col-lg-10 offset-lg-1 text-center">
                            <div className="section-top-title wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <h1>{ t('contact.title') }</h1>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li> / { t('contact.title') }</li>
                                </ul>
                            </div>
                            {/* <!-- //.HERO-TEXT --> */}
                        </div>
                        {/* <!--- END COL --> */}
                    </div>
                    {/* <!--- END CONTAINER --> */}
                </section>	
                
                {/* <!-- END SECTION TOP --> */}

                    
                    {/* <!-- START ADDRESS --> */}
                    {/* <section className="address_area section-padding">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
                                    <div className="single_address">
                                        <i className="ti-map"></i>
                                        <h4>Our Location</h4>
                                        <p>3481 Melrose Place, Beverly Hills <br /> CA 90210</p>
                                    </div>
                                </div>
                                <!-- END COL -->
                                <div className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                    <div className="single_address sabr">
                                        <i className="ti-mobile"></i>
                                        <h4>Telephone</h4>
                                        <p>(+1) 517 397 7100</p>
                                        <p>(+1) 411 315 8138</p>
                                    </div>
                                </div>
                                <!-- END COL -->
                                <div className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                    <div className="single_address">
                                        <i className="ti-email"></i>
                                        <h4>Send email</h4>
                                        <p>Info@example.com</p>
                                        <p>admin@example.com</p>
                                    </div>
                                </div>
                                <!-- END COL -->			  
                            </div>
                            <!--- END ROW -->
                        </div>
                        <!--- END CONTAINER -->
                    </section> */}
                    {/* <!-- END ADDRESS -->	 */}

                    
                    {/* <!-- CONTACT --> */}
                    <div id="contact" className="contact_area section-padding">
                        <div className="container">
                            <div className="section-title-two">
                                <h2>{ t('contact.subtitle') }</h2>
                            </div>				
                            <div className="row">					
                                <div className="offset-lg-1 col-lg-10 col-sm-12 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                    <div className="contact">
                                        <form className="form" name="enq" method="post" action="contact.php" >
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <label for="">{ t('contact.name') }</label>
                                                    <input type="text" name="inputname" id="inputname" className="form-control" required="required" onChange={(e)=>handlechangeinputname(e)} value={inputname} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="">{ t('contact.email') }</label>
                                                    <input type="email" name="inputemail" id="inputemail" className="form-control" required="required" onChange={(e)=>handlechangeinputemail(e)} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="">{ t('contact.phone') }</label>
                                                    <input type="text" name="inputtel" id="inputtel" className="form-control" required="required"  onChange={(e)=>handlechangeinputtel(e)} />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label for="">{ t('contact.message') }</label>
                                                    <textarea rows="6" name="inputmsg" id="inputmsg" className="form-control" required="required" onChange={(e)=>handlechangeinputmsg(e)}></textarea>
                                                </div>
                                                <div className="col-md-12 text-center">
                                                    <button type="button" value="Send message" name="submit" id="submitButton" className="btn_one" title="Submit Your Message!" onClick={enviarmensaje}>Send Message</button>
                                                    
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <!-- END COL  -->				 */}
                            </div>
                            {/* <!-- END ROW -->				 */}
                        </div>
                        {/* <!--- END CONTAINER --> */}
                    </div>
                    
                    {/* <!-- END CONTACT --> */}
		
            </React.Fragment>
		);
}

export default Contact;