import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import './App.css';

import { Provider } from 'react-redux';
import generateStore from './redux/store'

import Loader from './components/Loader';
import Navbar from './components/Navbar';
import Home from './components/Home'
import AboutUs from './components/AboutUs'
import Contact from './components/Contact'
import Course from './components/Course';
import Footer from './components/Footer';
import TerminosCondiciones from './components/TerminosCondiciones';

function App() {

	const store = generateStore()

  return (
		<Provider store={store}>
      <Loader />  
        <BrowserRouter>
      <div className="App">
          <Navbar />
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/courses" element={<Course />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/TerminosCondiciones" element={<TerminosCondiciones />} />
          </Routes>
        <Footer />
      </div>
        </BrowserRouter>
		</Provider>
  );
}

export default App;
