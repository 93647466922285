import React, { useEffect } from 'react';
import { Link } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import { useTranslation } from "react-i18next";

function Footer() {

	const { t } = useTranslation();

		return (
            <React.Fragment>
                	{/* <!-- START FOOTER --> */}
                        <div className="footer section-padding">
                            <div className="container">				
                                <div className="row">						
                                    <div className="col-lg-3 col-sm-6 col-xs-12">
                                        <div className="single_footer">
                                            <a href="/"><img src="assets/images/all-img/logo-ep-footer.png" alt="" /></a>         
                                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae risus nec dui venenatis dignissim.</p> */}
                                        </div>
                                        {/* <div className="foot_social">
                                            <ul>
                                                <li><a href="#">TW</a></li>
                                                <li><a href="#">FB</a></li>
                                                <li><a href="#">INS</a></li>
                                                <li><a href="#">YT</a></li>
                                            </ul>						
                                        </div>				 */}
                                    </div>
                                    {/* <!--- END COL -->						 */}
                                    <div className="col-lg-3 col-sm-6 col-xs-12">
                                        <div className="single_footer">
                                            <h4>{ t('navbar.courses') }</h4>
                                            <ul>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-1')}</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-2')}</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-3')}</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-4')}</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-5')}</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-6')}</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-7')}</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-8')}</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{t('home.courses.item-9')}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!--- END COL -->	 */}
                                    <div className="col-lg-3 col-sm-6 col-xs-12">
                                        <div className="single_footer">
                                            <h4>Empresa</h4>
                                            <ul>
                                                <li><Link className="nav-link" to="/">{ t('navbar.home') }</Link></li>
                                                <li><Link className="nav-link" to="/about-us">{ t('navbar.about-us') }</Link></li>
                                                <li><Link className="nav-link" to="/courses">{ t('navbar.courses') }</Link></li>
                                                <li><Link className="nav-link" to="/Contact">{ t('navbar.contact') }</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-12">
                                        <div className="single_footer">
                                            <h4>Contáctenos</h4>
                                            <ul>
                                                <li style={{color: "white"}}>info@grupexinternational.com</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!--- END COL -->	 */}
                                    {/* <div className="col-lg-3 col-sm-6 col-xs-12">
                                        <div className="single_footer">
                                            <h4>Contact Info</h4>
                                            <div className="sf_contact">
                                                <span className="ti-mobile"></span>
                                                <h3>Phone number</h3>
                                                <p>+88 457 845 695</p>
                                            </div>
                                            <div className="sf_contact">
                                                <span className="ti-email"></span>
                                                <h3>Email Address</h3>
                                                <p>example#yourmail.com</p>
                                            </div>
                                            <div className="sf_contact">
                                                <span className="ti-map"></span>
                                                <h3>Office Address</h3>
                                                <p>California, USA</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <!--- END COL -->	 */}
                                </div>
                                {/* <!--- END ROW -->		 */}
                                <div className="row fc">
                                    <div className="col-lg-6 col-sm-6 col-xs-12">
                                        <div className="footer_copyright">
                                            <p>&copy; 2024. All Rights Reserved.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-xs-12">
                                        <div className="footer_menu">
                                            <ul>
                                                <li><a href="/TerminosCondiciones">Términos Y Condiciones</a></li>
                                                {/* <li><a href="#">Privacy Policy</a></li> */}
                                                {/* <li><a href="#">Cookie Policy</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- END COL --> */}
                                </div>				
                            </div>
                            {/* <!--- END CONTAINER --> */}
                        </div>
                    {/* <!-- END FOOTER -->		 */}
            </React.Fragment>
		);
}

export default Footer;